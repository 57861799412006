import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import useToggler from "../hooks/useToggler";

const BookingForm = ({ parcours }) => {
  const fetch = useStaticQuery(graphql`
    query {
      parcours: allContentfulParcours {
        nodes {
          title
          slug
        }
      }
    }
  `);

  const allParcours = fetch.parcours.nodes;

  const [isChecked, toggle] = useToggler(false);
  const [selectedParcours, setSelectedParcours] = useState(parcours);

  const data = {
    emailPlaceHolder: "Votre e-mail",
    namePlaceHolder: "Nom et prénom",
    phonePlaceHolder: "Votre numéro pour être rappelé.",
    messagePlaceHolder:
      "Votre message : bien indiquer la date souhaitée et le nombre de participants",
    acceptanceLabel: (
      <div>
        J'ai lu et accepte la{" "}
        <a href="/mentions-legales">politique de confidentialité</a> de ce site
        et d'être recontacté par Strasbourg Running Tours dans le cadre de ma
        réservation.
      </div>
    ),
    buttonLabel: "DEMANDER UN CRENEAU",
  };

  const buttonClass = isChecked
    ? "button centered primary"
    : "button centered primary unchecked";

  return (
    <section className="mod mod--form bg-white" id="form">
      <div className="mod--form__container">
        <h2 className="mb-2 letter-spacing underlined">Réservez un créneau</h2>
        <form
          action="https://getform.io/f/4c29ebbb-3cae-4a78-a267-0cf87f4059d7"
          className="contact-form"
          id="contact-form"
          method="POST"
        >
          <input
            type="email"
            id="_replyto"
            name="_replyto"
            placeholder={data.emailPlaceHolder}
            required
          />

          <input
            type="text"
            id="name"
            name="name"
            placeholder={data.namePlaceHolder}
            required
          />

          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder={data.phonePlaceHolder}
            required
          />
          <label htmlFor="parcours">Sélectionner un parcours</label>
          <select
            name="parcours"
            id="parcours"
            value={selectedParcours.slug}
            onChange={(e) => setSelectedParcours(e.target.value)}
          >
            <option value="Aucun">--Choisir un parcours--</option>
            {allParcours.map((item) => (
              <option key={item.slug} value={item.slug}>
                {item.title}
              </option>
            ))}
          </select>

          <textarea
            id="message"
            name="message"
            placeholder={data.messagePlaceHolder}
            required
          />

          <label
            htmlFor="toggler"
            className={`mb-2 checkbox-label ${
              isChecked && "checkbox-label--checked"
            }`}
          >
            <input
              type="checkbox"
              className="contact-form__acceptance"
              checked={isChecked}
              required
              name="toggler"
              id="toggler"
              onChange={toggle}
            />
            {data.acceptanceLabel}{" "}
          </label>
          <button className={buttonClass} type="submit">
            {data.buttonLabel}
          </button>
        </form>
      </div>
    </section>
  );
};

export default BookingForm;
