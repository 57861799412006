import { graphql, Link } from "gatsby";
import React from "react";
import Head from "../../components/head";
import Layout from "../../components/layout";
import BlocsContent from "../../components/sections/BlocsContent";
import BookingForm from "../../components/sections/BookingForm";
import ContactLink from "../../components/sections/ContactLink";
import HeroStandard from "../../components/sections/HeroStandard";
import ParcoursDetail from "../../components/sections/ParcoursDetail";
import { FaArrowLeft } from "react-icons/fa";

export const query = graphql`
  query ($slug: String!) {
    parcours: contentfulParcours(slug: { eq: $slug }) {
      title
      slug
      accroche
      excerpt {
        excerpt
      }
      seoTitle
      seoDescription
      image {
        gatsbyImageData(width: 600)
        title
      }
      time
      difficulty
      price1
      price2
      price3
      price4
      price5
      special_label
      special_value
      content {
        ... on Node {
          ... on ContentfulBlocArticle {
            __typename
            id
            title
            textContent {
              raw
            }
            image {
              title
              gatsbyImageData(
                layout: FULL_WIDTH
                width: 600
                placeholder: DOMINANT_COLOR
              )
            }
            isImageLeft
            link
            buttonLabel
            bgColor
          }

          ... on ContentfulBlocVideo {
            __typename
            id
            title
            video_url
            bgColor
          }

          ... on ContentfulBlocGalerieDePhotos {
            __typename
            id
            title
            text {
              raw
            }
            bgColor
            images {
              title
              gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  }
`;

const Parcours = ({ data, location }) => {
  const {
    title,
    seoTitle,
    seoDescription,
    image,
    accroche,
    excerpt,
    time,
    difficulty,
    price1,
    price2,
    price3,
    price4,
    price5,
    special_label,
    special_value,
    content,
  } = data.parcours;

  return (
    <Layout>
      <Head title={seoTitle || title} description={seoDescription} />
      <HeroStandard title={title} />
      <div className="breadcrumbs">
        <Link to="/">Accueil</Link> > <Link to="/parcours">Nos parcours</Link> >{" "}
        <span>{title}</span>
      </div>
      <ParcoursDetail
        title={title}
        image={image}
        accroche={accroche}
        excerpt={excerpt.excerpt}
        price1={price1}
        price2={price2}
        price3={price3}
        price4={price4}
        price5={price5}
        special_label={special_label}
        special_value={special_value}
        difficulty={difficulty}
        time={time}
        location={location}
      />
      {content && <BlocsContent blocs={content} />}
      <BookingForm parcours={data.parcours} />
      <div className="mt-2 mb-2">
        <Link to="/parcours/" className="button black mb-0 centered with-picto">
          <FaArrowLeft />
          Voir les autres parcours
        </Link>
      </div>
      <ContactLink />
    </Layout>
  );
};

export default Parcours;
