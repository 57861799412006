import React from "react";

const Prices = ({
  price1,
  price2,
  price3,
  price4,
  price5,
  special_label,
  special_value,
}) => {
  return (
    <div className="card--prices mt-1-5 mb-1 bg-white">
      <div className="card--prices__line fw-700">
        Tarifs TTC par personne en fonction du nombre de participants
      </div>
      <div className="card--prices__line">
        <div className="card--prices__person">1 personne</div>
        <div className="card--prices__price">{`${
          price1 ? price1 + "€ par personne" : "/"
        } `}</div>
      </div>
      <div className="card--prices__line">
        <div className="card--prices__person">2 personnes</div>
        <div className="card--prices__price">{`${
          price2 ? price2 + "€ par personne" : "/"
        } `}</div>
      </div>
      <div className="card--prices__line">
        <div className="card--prices__person">3 personnes</div>
        <div className="card--prices__price">{`${
          price3 ? price3 + "€ par personne" : "/"
        } `}</div>
      </div>
      <div className="card--prices__line">
        <div className="card--prices__person">4 personnes</div>
        <div className="card--prices__price">{`${
          price4 ? price4 + "€ par personne" : "/"
        } `}</div>
      </div>
      <div className="card--prices__line">
        <div className="card--prices__person">5 personnes et +</div>
        <div className="card--prices__price">{`${
          price5 ? price5 + "€ par personne" : "/"
        } `}</div>
      </div>
      {(special_label || special_value) && (
        <div className="card--prices__line">
          <div className="card--prices__person">{special_label}</div>
          <div className="card--prices__price">{special_value}</div>
        </div>
      )}
    </div>
  );
};

export default Prices;
