import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Prices from "../atoms/Prices";
import Share from "./Share";

const ParcoursDetail = ({
  title,
  image,
  isImageLeft = true,
  accroche,
  excerpt,
  price1,
  price2,
  price3,
  price4,
  price5,
  special_label,
  special_value,
  difficulty,
  time,
  location,
}) => {
  const link = "#form";
  const buttonLabel = "Réserver un créneau";
  return (
    <>
      <section className="mod bg-white">
        <div className="grid-container">
          <article className="block--article mod--parcours">
            <div
              className={`grid-x grid-margin-x grid-margin-y align-center align-stretch ${
                isImageLeft ? "" : "flex-dir-row-reverse"
              }`}
            >
              {image && (
                <div className="cell medium-8 large-6">
                  <div className="block--article__img ">
                    <GatsbyImage
                      image={getImage(image)}
                      alt={image.title || title}
                    />
                  </div>
                  <div className="position-relative">
                    <div className="sticker">
                      {difficulty && (
                        <div className="h4 text-secondary">
                          Niveau {difficulty}
                        </div>
                      )}
                      {time && (
                        <div className="h4 text-black">Durée: {time}</div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div
                className={`cell ${
                  image ? "medium-8 large-6" : "medium-10 large-12"
                }`}
              >
                <div className="block--article__content mod--parcours__content">
                  {title && (
                    <h2
                      className={`h3 mb-1 letter-spacing underlined ${
                        image ? "underlined--left" : ""
                      }`}
                    >
                      {title}
                    </h2>
                  )}
                  {accroche && (
                    <h3 className={`h4 mb-1 letter-spacing`}>{accroche}</h3>
                  )}
                  {excerpt && (
                    <div className="bloc--article__text m-b-2">{excerpt}</div>
                  )}

                  <Prices
                    price1={price1}
                    price2={price2}
                    price3={price3}
                    price4={price4}
                    price5={price5}
                    special_label={special_label}
                    special_value={special_value}
                  />

                  {link && buttonLabel && (
                    <Link
                      className={`button primary mt-1-5 mb-0 ${
                        image ? "right" : "centered"
                      }`}
                      to={link}
                    >
                      {buttonLabel}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>
      <Share title={title} url={location.href} />
    </>
  );
};

export default ParcoursDetail;
